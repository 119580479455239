import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [7,[2]],
		"/(app)/plan/[type]": [8,[2,3,4]],
		"/(app)/plan/[type]/[short]": [9,[2,3,4,5]],
		"/(app)/plan/[type]/[short]/filter": [10,[2,3,4,5]],
		"/(app)/search": [11,[2]],
		"/(app)/search/free/room": [12,[2]],
		"/(app)/settings": [13,[2,6]],
		"/(app)/settings/credentials": [14,[2,6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';